import {GET_CHAT_LIST, 
  UPDATE_WITH_TEMP_MESSAGE, 
  UPDATE_TEMP_WITH_MESSAGEID, 
  CHANGE_USER_PRESENCE, 
  RECEIVE_NEW_MESSAGE,
  CHAT_LIST_ON_RECEIVE_MESSAGE, RESET_BADGE } from '../constants/ActionTypes';
import { buildConversationObjFromMessage } from '../util/commonFunctions';

import { Map, List, fromJS, toJS } from 'immutable';
import _ from 'lodash';

let initialState = { 
  conversations: [],
  messages: [],
  loadingConvs: false,
  error: {}
}

export default function ChatReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case (GET_CHAT_LIST+'_REQUEST'): {
      // console.log('promise started');
      return {...state, loadingConvs: true};
      break;
    }

    case (GET_CHAT_LIST+'_SUCCESS'): {
      // console.log('promise fulfilled');
      let response = action.payload.data;
      // console.log(response);
      return {...state, loadingConvs: false, conversations: response.convs, messages: response.messages};
      break;
    }

    case (GET_CHAT_LIST+'_FAIL'): {
      // console.log('promise rejected');
      return {...state, loadingConvs: false, error: {someError: true}};
      break;
    }
    /*
      Update Conv Messages with Temp message 
    */
    case (UPDATE_WITH_TEMP_MESSAGE): {
      var pl = action.payload;
      var messages = [...state.messages];
      var conversations = [...state.conversations];

      var filteredConversation = _.remove(conversations, function(conv) {
        return conv.remoteUser === pl.userDetails.userName;
      })

      if(!conversations) {
        conversations = [];
      }

      var newConvObj = buildConversationObjFromMessage(filteredConversation[0], pl.newMessage, 's', pl.userDetails);
      conversations.unshift(newConvObj);

      _.remove(messages, function(message) {
        return message.messageId === pl.oldMessageId;
      })

      if(!messages) {
        messages = [];
      }

      messages.push(pl.newMessage);

      return {...state, conversations: conversations, messages: messages};
      break;
    }
    /*
      Update Temp Messages with messageId 
    */
    case (UPDATE_TEMP_WITH_MESSAGEID): {
      var pl = action.payload;
      var messages = [...state.messages];
      var convs = [...state.conversations];
      var newMessagesList, newConvList;

      var sentMessageIndex = _.findIndex(messages, function(message) {
        return (message.trackId == pl.tempId);
      })

      var convIndex = _.findIndex(convs, function(conv) {
        return (conv.remoteUser == pl.userDetails.userName);
      })

      if(sentMessageIndex > -1) {
        var messagesList = fromJS(messages);
        newMessagesList = messagesList.setIn([sentMessageIndex, 'messageId'], pl.newMessage.messageId)
                            .setIn([sentMessageIndex, 'status'], pl.newMessage.status)
                            .setIn([sentMessageIndex, 'ts'], pl.newMessage.ts)
                                
      }
      if(convIndex > -1) {
        var convList = fromJS(convs);
        newConvList = convList.setIn([convIndex, 'ts'], pl.newMessage.ts)
      }
      return {...state, conversations: newConvList.toJS(), messages: newMessagesList.toJS()};
      break;
    }

    /*
      Change User Status
    */
    case (CHANGE_USER_PRESENCE): {
      var pl = action.payload;
      var convs = [...state.conversations];
      var newConvList = [...state.conversations];

      var convIndex = _.findIndex(convs, function(conv) {
        // console.log(conv);
        // console.log(pl.userName);
        return (conv.remoteUser == pl.userName);
      })

      // console.log(convIndex);

      if(convIndex > -1) {
        var convList = fromJS(convs);
        newConvList = convList.setIn([convIndex, 'userDetails', 'isOnline'], pl.isOnline);
      }

      // console.log(state);
      return {...state, conversations: newConvList.toJS()};
      break;
    }

    /*
      on receive new message
    */
    case (CHAT_LIST_ON_RECEIVE_MESSAGE): {
      var pl = action.payload;
      var messages = [...state.messages];
      var conversations = [...state.conversations];

      var filteredConversation = _.remove(conversations, function(conv) {
        return conv.remoteUser === pl.newMessage.from;
      })

      if(!conversations) {
        conversations = [];
      }

      var newConvObj = buildConversationObjFromMessage(filteredConversation[0], pl.newMessage, 'r', pl.userDetails);
      conversations.unshift(newConvObj);

      var filteredMessages = _.filter(messages, (message) => {
        return (message.from == pl.newMessage.from || message.to == pl.newMessage.from);
      })

      var orderByMessages = _.sortBy(filteredMessages, (message) => {return (message.ts)});

      if(orderByMessages.length > 0) {
        _.remove(messages, function(message) {
          return message.messageId === orderByMessages[0].messageId;
        })
      }
      messages.push(pl.newMessage);
      // console.log(conversations);
      // console.log(pl.newMessage);
      return {...state, conversations: conversations, messages: messages};
      break;
    }

    case (RESET_BADGE+ '_SUCCESS'): {
      var pl = action.payload;
      var convs = [...state.conversations];
      var newConvList = fromJS(convs);

      var convIndex = _.findIndex(convs, function(conv) {
        return (conv.remoteUser == pl.data.remoteUser);
      });
      
      if(convIndex > -1) {
        var convList = fromJS(convs);
        newConvList = convList.setIn([convIndex, 'badgeCount'], 0);
      }

      return {...state, conversations: newConvList.toJS()};
      break;
    }

    default: {
      return state;
    }
  }
  return state;
}
