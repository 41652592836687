import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';

import Dashboard from 'containers/Dashboard';
import LoginPage from 'containers/LoginPage';
import RegisterPage from 'containers/RegisterPage';
import PageNotFound from 'components/PageNotFound';
import PrivacyPolicy from 'components/PrivacyPolicy';
import HomePage from 'containers/HomePage';
import './stylesheets/main.scss';

import { hot } from 'react-hot-loader';

import { Provider } from 'react-redux';
import { store, persistor } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';

const maxHeight = { height: '100%' };

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    sessionStorage.getItem('userLogged') ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/signin',
        state: { from: props.location }
      }}/>
    )
  )}/>
);

class App extends React.Component {
  render() {
    return (
       <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <div style={maxHeight}>
              <Switch>
                <Route path="/" exact={true} render={() => (
                  sessionStorage.getItem('userLogged') ? (
                    <Redirect to="/dashboard/messages"/>
                  ) : (
                    <HomePage/>
                  )
                )}>         
                </Route>
                
                <Route path='/signin' render={() => (
                  sessionStorage.getItem('userLogged') ? (
                    <Redirect to="/dashboard/messages"/>
                  ) : (
                    <LoginPage />
                  )
                )} />
                      <Route path='/register' render={() => (
                  sessionStorage.getItem('userLogged') ? (
                    <Redirect to="/dashboard/messages"/>
                  ) : (
                    <RegisterPage/>
                  )
                )} />
                <Route path='/privacy' render={() => (
                    <PrivacyPolicy />
                )} />
                <PrivateRoute path='/dashboard' component={Dashboard}/>
                <Route component={PageNotFound}></Route>
              </Switch>
            </div>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
};

hot(module)(App);

ReactDOM.render(<App />, document.getElementById('root'));
