import React from 'react';
import { Route, NavLink } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import icon from 'assets/images/icon.png';

class Header extends React.Component {

  goToHome = () => {
    this.props.history.push('/');
  }
  
  render() {
    return (
      <div className="login-top-header header-dark">
        <div className="header-icon" onClick={this.goToHome}>
          <img src={icon} alt="Tigpix"/>
        </div>
        <div>
        <NavLink className="signup-top-button" to="/register">
          <div>Sign up</div>  
        </NavLink>
        </div>
        <NavLink className="login-top-button" to="/signin">
          <div>Log In</div> 
        </NavLink>
      </div> 
    );
  }
}

export default withRouter(Header);
