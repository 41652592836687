import React from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import UserRegistration from 'containers/UserRegistration';

export default class RegisterPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  onRegister = () => {

  }
  
  render() {
    return (
      <div className="flex-container max-height">
        <Header />
        <div className="row-element row">
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
          <div className="col-lg-4 col-md-8 col-sm-12 signin-container">
            <div className="regform-heading">Start Texting</div>
            <div className="regform-sub-heading">Create an account and get in touch with your friends</div>
            <UserRegistration onRegister={this.onRegister} buttonClassName="signup-btn reg-button" />
          </div>
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
        </div>
        <Footer/>
      </div>
    );
  }
}
