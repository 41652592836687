import io from 'socket.io-client';
import { store } from '../store/configureStore';
import * as socketActions from 'actions/socketActions';

var socket;

var registerSocket = function(token) {
  socket =  io.connect('http://34.200.125.181:5028', {
    'query': 'token=' + token
  });
}

var sendMessage = function() {
  socket.emit('file2Event', {key: 'value'});
}

var listenToReceiveMessage = function() {
  socket.on('receivedMessageToServer', function(data) {
    console.log('new message received');
    store.dispatch(socketActions.updateChatListOnMessage(data.newMessage, data.userDetails));
    store.dispatch(socketActions.receiveNewMessage(data.newMessage, data.userDetails));
  })
}

var listenToPresenceStatus = function(username) {
  if (socket._callbacks['$'+username] == undefined ) {
    socket.on(username, function(data) {
      store.dispatch(socketActions.changeUserPresence(data));
    })
  }
}

var unregisterSocket = function() {
  socket.disconnect();
}

export { registerSocket, sendMessage, listenToReceiveMessage, listenToPresenceStatus, unregisterSocket };