import React from 'react';
import PropTypes from 'prop-types';

import iconPaths from 'assets/icon_selection.json';

function getPath(iconName) {
  const icon = iconPaths.icons.find(i => i.tags.indexOf(iconName) > -1);
  if (icon) {
    return icon.paths.join(' ');
  } else {
    console.warn(`icon ${iconName} does not exist.`);
    return '';
  }
}

export default class Icon extends React.Component {
  constructor(props) {
    super(props);
    
  }

  componentWillMount() {
    
  }

  render() {
    return (
      <div className="svg-icon">
        <svg viewBox="0 0 1024 1024">
          <path d={getPath(this.props.icon)}></path>
    </svg>
      </div>
    );
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};