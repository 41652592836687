import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Emoji } from 'emoji-mart';
import {emojify} from 'react-emojione';
import emojione from 'emojione';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/messageActions';
import ContentEditable from 'components/ContentEditable';
import EmojiPicker from 'components/EmojiPicker';
import winner from 'assets/images/winner.png';
import { generateTrackId } from 'util/commonFunctions';

import moment from 'moment'
import _ from 'lodash';

let textFieldHeight = 53;
const initialContentEditHeight = 47;
const NumberOfMessages = 40;

class ChatDetailContainer extends React.Component {

  constructor(props) {
    super(props);  

    this.state = {
      messages: [],
      userDetails: {},
      isOnline: false,
      hasMoreMessages: false,
      scrollHeight: 0,
      listenScroll: true,
      disableInfiniteScroll: false,
      inputValue: '',
      appendEmoji: {},
      closeEmoji: false,
      showDownArrow: false,
      chatTextContainerHeight: { height: textFieldHeight },
      messageBodyHeight: { height: 'calc(100% - ' + textFieldHeight + 'px)' }
    }   
  }

  componentWillMount() {
    // console.log('--------');
    // console.log(this.props.userDetails);
    this.setState({...this.state, userDetails: this.props.userDetails, isOnline: this.props.userDetails.isOnline}, function(){
      this.filterMessages();
    });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.sendMessage.messageSent) {
      //this.replaceTempMsg(nextProps);    
    }

    this.setState({...this.state, userDetails: nextProps.userDetails, isOnline: nextProps.userDetails.isOnline});

    //console.log(this.state);
    // //console.log(this.props);
    //console.log(nextProps);
    // //console.log(this.state.userDetails.userName);

    if((this.props.userDetails.userName != nextProps.userDetails.userName)) {
      // //console.log('-------------------state reset ----------------------');
      this.resetStateWithUser(nextProps.userDetails);
    }
    //console.log('------------Next props------------');
    ////console.log(nextProps);

    if(nextProps.receiveMessage.onReceiveFlag) {
      this.props.actions.resetOnReceiveFlag();
      if (nextProps.receiveMessage.newMessage.from === this.props.userDetails.userName) {
        let messages = [...this.state.messages];
        let messageIndex = _.findIndex(messages, function(message) {
          return (message.messageId ==nextProps.receiveMessage.newMessage.messageId);
        })
        if(messageIndex === -1) {
          messages.push(nextProps.receiveMessage.newMessage);
          let newState = {...this.state, messages: messages};
          this.setState(newState, function() {
            setTimeout(() => {
              this.scrollToBottom('smooth');
            })
          })
        }
      }
    }
  }

  scrollToBottom(behaviorParam) {
    behaviorParam = behaviorParam || 'instant';
    //console.log('------------Scroll to Bottom------------');
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    if (node) {
      node.scrollIntoView({ behavior: behaviorParam });
    }
  }

  resetStateWithUser(userDetails) {
    this.setState({messages: [], userDetails: userDetails, listenScroll: false, isOnline: userDetails.isOnline}, function() {
      this.filterMessages();
    });
  }

  replaceTempMsg(props) {
    let modifiedMessage = {};
    let sentMessageIndex = _.findIndex(this.state.messages, (message) => {
      return message.trackId === props.sendMessage.trackId;
    })

    // //console.log(sentMessageIndex);

    if(sentMessageIndex > -1) {
      var messages = [...this.state.messages];
      messages[sentMessageIndex].messageId = props.sendMessage.messageId;
      messages[sentMessageIndex].status = props.sendMessage.status;
      messages[sentMessageIndex].ts = props.sendMessage.ts;
      modifiedMessage = messages[sentMessageIndex];
      var newState = {...this.state, messages: messages};
      this.setState(newState);
    }
    return props.sendMessage;
  }

  deleteOldMessageFromState(messageId) {
    var messages = [...this.state.messages];
    _.remove(messages,(message) => {
      return message.messageId = messageId;
    })
    var newState = {...this.state, messages: messages};
    this.setState(newState);
  }

  filterMessages() {
    //console.log('----------Filtering Messages---------------');
    // //console.log(this.props);
    let messages = _.filter(this.props.chatList.messages, (message) => {
      return (message.from === this.props.userDetails.userName || message.to === this.props.userDetails.userName);
    });
    let orderByMessages = _.sortBy(messages, (message) => {return (message.ts)});
    var newState = {...this.state, messages: orderByMessages};
    this.setState(newState, function() {
      this.scrollToBottom("instant");
      this.setState({...this.state, listenScroll: true, disableInfiniteScroll: false});
    });
  }

  messagesPanel() {
    var self = this;
    var messageDate = '';
    var messageDirection, oldMessageDirection, newMessageDirection, messageName = '';

    if(this.state.messages.length > 0) {
      var messagesListMap = [...this.state.messages];
      var messagesList = messagesListMap.map(function(message) {
        var classNamesArray = ['message'];
        if (message.to === self.props.userDetails.userName) {
          oldMessageDirection = messageDirection;
          classNamesArray.push('to');
          newMessageDirection = 'to';
          messageDirection = newMessageDirection;
          messageName = self.props.userInfo.firstName;
        } else {
          oldMessageDirection = messageDirection;
          classNamesArray.push('from');
          newMessageDirection = 'from';
          messageDirection = newMessageDirection;
          messageName = self.props.userDetails.firstName;
        }

        var messageTS = moment(message.ts).format('h:mm a');
        var oldDate = messageDate;
        var currentMessageDate = moment(message.ts).format('MMM Do YYYY');
        var newDate = currentMessageDate;
        messageDate = currentMessageDate;
        // //console.log(message.body);
        if(message.messageType === 'SMS-Text') {
          const messageBody = emojione.toImage(message.body).replace('&nbsp;', ' ');
          // //console.log(message.messageId);
          // {self.displayNameOnMessage(oldMessageDirection, newMessageDirection, messageName)}
          return (
            <div key={message.messageId}>
              {self.displayDate(oldDate, newDate)}     
              <div className={classNamesArray.join(' ')}>
                <div className="emoji-block-in-text-message" dangerouslySetInnerHTML={{__html: messageBody}}></div>
                <div className="message-ts">{messageTS}</div>
              </div>
            </div>
          )
        } else {
          return <div key={message.messageId} className={classNamesArray.join(' ')}>Other message</div>
        }
      })
      return (
        <div className="messages-wrapper">
          
            {messagesList}

          <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
      );
    } else {
      return (
        <div className="empty-msgs-img">
          <img src={winner}/>
          <div className="empty-msgs-txt"> Say Hi to {this.props.userDetails.firstName}</div>
        </div>
      );
    }
  }

  displayDate(oldDate, newDate) {
    var el = <div></div>;
    if (oldDate != newDate) {
      el = (
        <div className="message-date">{newDate}</div>
      );
    }
    return el;
  }

  sendMessage(messageBody) {
    var to = this.props.userDetails.userName;
    var from = this.props.userInfo.userName;
    var reqBody = this.generateMessageRequest(messageBody, to, from, 'SMS-Text', false);
    //console.log(this.state);
    let deleteMessageIndex = this.state.messages.length - (NumberOfMessages);
    let oldMessageId = this.state.messages[deleteMessageIndex] ? this.state.messages[deleteMessageIndex].messageId : '';
    reqBody.messageId = reqBody.trackId;
    let newMessage = {...reqBody};
    newMessage.ts = moment().toISOString();
    this.props.actions.changeStoreWithTempMessage(oldMessageId, newMessage, this.props.userDetails);
    this.props.actions.sendMessage(reqBody).then((resp) => {
      this.props.actions.changeStoreWithMessageId(resp.payload.data.trackId, resp.payload.data, this.props.userDetails);
      let modifiedMessage = this.replaceTempMsg({sendMessage: resp.payload.data});
    })
    let newMessagesArray = this.state.messages;
    newMessagesArray.push(reqBody);
    var newState = {...this.state, messages: newMessagesArray, userDetails: this.props.userDetails};
    this.setState(newState, function() {
      setTimeout(() => {
        this.scrollToBottom("smooth");
      });
    });
  }

  generateMessageRequest(body, to, from, messageType, isGroup) {
    return {
      body: body,
      to: to,
      from: from,
      messageType: messageType,
      isGroup: isGroup,
      trackId: generateTrackId()
    };
  }

  getNameOfUser() {
    if(this.props.userDetails.name) {
      return this.props.userDetails.name;
    }
    return this.props.userDetails.firstName + ' ' + this.props.userDetails.lastName;
  }

  showUserStatus() {
    if(this.props.userDetails.isOnline) {
      return 'Online';
    }
    return '';
  }

  scrollFunction(scrollHeight, scrollTop) {
    let self = this;
    
    //console.log('----------scroll function-----------');
    //console.log(this.state);
    let time = (new Date()).getTime();
    let reqObj = {lastMsgNumb : this.state.messages[0].messageNumber-1, 
      numberOfMsgs: 40, userName: this.props.userInfo.userName, 
      remoteUserName: this.props.userDetails.userName};
    this.props.actions.loadMoreMessages(reqObj).then((respObj) => {
      let resp = respObj.payload.data;
      let orderByMessages = _.sortBy(respObj.payload.data.messages, (message) => {return (message.messageNumber)});
      let messages = [...orderByMessages, ...self.state.messages];
      let disableInfiniteScroll = !(orderByMessages.length > 0);
      let newState = {...self.state, messages: messages, hasMoreMessages: false, disableInfiniteScroll: disableInfiniteScroll};
      let now = (new Date()).getTime();
      //console.log('----------load messages-----------');
      //console.log(now - time);
      self.setState(newState, function() {
        let node = ReactDOM.findDOMNode(self.chatBox);
        //console.log(node);
        let timenow = (new Date()).getTime();
        if (node) {
          //console.log(node.scrollHeight - scrollHeight);
          window.requestAnimationFrame(function() {
            let timenow1 = (new Date()).getTime();

            //console.log('delay time' + (timenow1-timenow));
            node.scrollTop = (node.scrollHeight - scrollHeight);
            self.setState({...self.state, listenScroll: true})
          });
          // setTimeout(()=>{
          //   let timenow1 = (new Date()).getTime();

          //   //console.log('delay time' + (timenow1-timenow));
          //   node.scrollTop = (node.scrollHeight - scrollHeight);
          //   self.setState({...self.state, listenScroll: true})

          // })
        }
      });
    });
    
  }

  addEmojiToText = (emoji) => {
    this.setState({...this.state, appendEmoji: emoji});
  }

  handleSendMesssage = (message) => {
    let self = this;
    this.setState({...this.state, closeEmoji: true}, function() {
      self.sendMessage(message);
      // console.log(this.state);
      setTimeout(() => {
        self.setState({...this.state, closeEmoji: false});
      }, 1000);
    });
  }

  heightChange = (height) => {
    let newHeight = height + 8;
    let messageBodyHeight = {height: 'calc(100% - ' + newHeight + 'px)'};
    this.setState({...this.state, chatTextContainerHeight: {height: newHeight}, messageBodyHeight}, function() {
      this.scrollToBottom('instant');
    });
  }

  listenScrollEvent = (e) => {
    if(e.target.scrollHeight > e.target.scrollTop + 900) {
      if(!this.state.showDownArrow) {
        this.setState({...this.state, showDownArrow: true});
      }
    } else {
      if(this.state.showDownArrow) {
        this.setState({...this.state, showDownArrow: false})
      }  
    }
    if(!this.state.disableInfiniteScroll) {
      if (e.target.scrollTop < 100) {
        if(this.state.listenScroll) {
          let self = this;
          let scrollHeight = e.target.scrollHeight;
          let scrollTop = e.target.scrollTop;
          this.setState({...this.state, listenScroll: false}, function() {
            if (this.state.messages.length > 0){
              self.scrollFunction(scrollHeight, scrollTop);
            }
          })
        }
      }
    }
  }
  
  render() {
    let userStatus = ['online-status'];
    if (this.state.isOnline) {
      userStatus.push('is-online');
    }
    return (      
      <div className="chat-detail-container max-height">
        <div className="chat-detail-header">
          <div className="title"><span className={userStatus.join(' ')}></span>{this.getNameOfUser()}</div>
        </div>
        <div className="chat-body">
          <div style={this.state.messageBodyHeight} className="messages-body" onScroll={this.listenScrollEvent} ref={(el) => { this.chatBox = el; }}>
            {this.messagesPanel()}
          </div>
          <div style={this.state.chatTextContainerHeight} className="chat-text-container">
            <div className="chat-text-icons">
              <EmojiPicker onEmojiClick={this.addEmojiToText} closeEmoji={this.state.closeEmoji}/>
            </div>
            <ContentEditable
                html={emojify(this.state.inputValue, {style: {height: 24}})} // innerHTML of the editable div
                disabled={false}       // use true to disable edition
                onSendMessage={this.handleSendMesssage} // handle innerHTML change
                appendEmoji={this.state.appendEmoji}
                heightChangeEvent={this.heightChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

ChatDetailContainer.propTypes = {
  userDetails: PropTypes.object.isRequired
};

ChatDetailContainer.defaultProps = {
  userDetails: {}
}

function mapStateToProps(state) {
  return {
    chatList: state.chatList,
    userInfo: state.userInfo,
    sendMessage: state.sendMessage,
    receiveMessage: state.receiveMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatDetailContainer));