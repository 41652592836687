import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/loginActions';
import { registerSocket } from 'util/socketEvents';

import InputField from 'components/InputField';
import { validateInputField } from 'util/commonFunctions';
import messages from 'constants/Messages.json';

const margin20 = {
  marginBottom: '20px'
};

const fieldValidations = {
  firstName: {required: true, min: 2, max: 20, pattern: '^[a-zA-Z ]+$'}, 
  lastName: {required: true, min: 2, max: 20, pattern: '^[a-zA-Z ]+$'}, 
  email: {required: true, pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'},
  userName: {required: true, min: 6, max: 20},
  password: {required: true, min:6, max: 20},
  confirmPassword: {required: false, min:6, max: 20}
};

class UserRegistration extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      firstName: { text: '', hasError: false, errorText: '', showError: false },
      lastName: { text: '', hasError: false, errorText: '', showError: false },
      email: { text: '', hasError: false, errorText: '', showError: false },
      userName: { text: '', hasError: false, errorText: '', showError: false },
      password: { text: '', hasError: false, errorText: '', showError: false },
      confirmPassword: { text: '', hasError: false, errorText: '', showError: false },
      userNameExists: false
    }
  }

  componentWillMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.userInfo.userNameExists && !this.state.userName.hasError) {
      let clonedState = {...this.state};
      clonedState['userName'].hasError = true;
      clonedState['userName'].errorText = messages.errors['userName']['userUnique'];
      clonedState['userName'].showError = true;
      clonedState['userNameExists'] = true;
      this.setState(clonedState);
    }
  }

  registerUser(firstName, lastName, userName, email, password) {
    this.props.actions.registerUser({firstName, lastName, userName, email, password}).then(() => {
      let access_token = sessionStorage.getItem('a_t');
      registerSocket(access_token);
      this.props.history.push("/dashboard/messages");
    })
    .catch((response) => {
      //handle form errors
    })
  }

  onInputChange = (e) => {
    let clonedState = {...this.state};

    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const error = validateInputField(fieldValue, fieldValidations[fieldName]);
    if(error && clonedState[fieldName]['errorText']) {
      clonedState[fieldName]['showError'] = clonedState[fieldName]['errorText'].length > 0;
      clonedState[fieldName]['errorText'] = messages.errors[fieldName][error];
    } else {
      clonedState[fieldName]['errorText'] = '';
      clonedState[fieldName]['hasError'] = false;
      clonedState[fieldName]['showError'] = false;
    }

    clonedState[fieldName]['text'] = fieldValue;
    this.setState(clonedState);
  };

  onFocus = (inputName) => {
    let clonedState = {...this.state};
    clonedState[inputName]['showError'] = true;
    this.setState(clonedState);
  }

  onBlur = (inputName) => {
    let clonedState = {...this.state};
    clonedState[inputName]['showError'] = false;
    this.setState(clonedState);

    if(inputName === 'userName' && this.state.userName.text.length > 5) {
      this.props.actions.isUniqueUser({userName: this.state.userName.text});
    }
  }

  submitRegistration = () => {
    let clonedState = {...this.state};
    let foundError = false;

    _.forEach(fieldValidations, function(value, key) {
      let error = validateInputField(clonedState[key]['text'], value);
      if(error) {
        clonedState[key]['hasError'] = true;
        clonedState[key]['errorText'] = messages.errors[key][error];
        if(!foundError) {
          clonedState[key]['showError'] = true;
          foundError = true;
        }
      }
    });
    this.setState(clonedState);

    if (foundError || clonedState.userNameExists) {
      return;
    }

    const { firstName, lastName, userName, email, password } = clonedState;
    this.registerUser(firstName.text, lastName.text, userName.text, email.text, password.text);

    this.props.onRegister && this.props.onRegister();
  }

  render() {
    return (
      <div className="registration-container">
        <div className="row">
          <div style={margin20} className="col-md-6 col-sm-12">
            <InputField 
              type="text"
              name="firstName"
              placeholder="First Name"
              value={this.state.firstName.text}
              onChange={this.onInputChange}
              hasError={this.state.firstName.hasError}
              tooltipText={this.state.firstName.errorText}
              showTooltip={this.state.firstName.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>
          <div style={margin20} className="col-md-6 col-sm-12">
            <InputField 
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={this.state.lastName.text}
              onChange={this.onInputChange}
              hasError={this.state.lastName.hasError}
              tooltipText={this.state.lastName.errorText}
              showTooltip={this.state.lastName.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>
          
        </div>
        <div className="row">
          <div style={margin20} className="col-md-12 col-sm-12">
            <InputField 
              type="email"
              name="email"
              placeholder="Email"
              value={this.state.email.text}
              onChange={this.onInputChange}
              hasError={this.state.email.hasError}
              tooltipText={this.state.email.errorText}
              showTooltip={this.state.email.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>                 
        </div>
        <div className="row">
          <div style={margin20} className="col-md-12 col-sm-12">
            <InputField 
              type="text"
              name="userName"
              placeholder="User Name"
              value={this.state.userName.text}
              onChange={this.onInputChange}
              hasError={this.state.userName.hasError}
              tooltipText={this.state.userName.errorText}
              showTooltip={this.state.userName.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>               
        </div>
        <div className="row">
          <div style={margin20} className="col-md-12 col-sm-12">
            <InputField 
              type="password"
              name="password"
              placeholder="Password"
              value={this.state.password.text}
              onChange={this.onInputChange}
              hasError={this.state.password.hasError}
              tooltipText={this.state.password.errorText}
              showTooltip={this.state.password.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>                 
        </div>
        <div className="row">
          <div style={margin20} className="col-md-12 col-sm-12 login-btn-container">
            <button className={this.props.buttonClassName} onClick={this.submitRegistration}>Start using Tigpix</button>
          </div>                 
        </div>
      </div>
    );
  }
}

UserRegistration.propTypes = {
  onRegister: PropTypes.func,
  buttonClassName: PropTypes.string
};

UserRegistration.defaultProps = {
  onRegister: null,
  buttonClassName: ''
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistration));