import React from 'react';

import { withRouter } from "react-router-dom";

class Footer extends React.Component {

  constructor(props) {
    super(props);
  }

  handlePrivacyClick = () => {
    this.props.history.push("/privacy");
  }
  
  render() {
    return (      
      <div className="footer">
        <p className="text">Copyright: © Tigpix | All rights reserved | &nbsp;
          <a onClick={this.handlePrivacyClick} className="privacy-anchor">Privacy Policy</a>
        </p>
      </div>
    );
  }
}

export default withRouter(Footer);