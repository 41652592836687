import React from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import emojione from 'emojione';
import { Emoji } from 'emoji-mart';

export default class ContentEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      messageText: '',
      inputBoxHeight: 47
    }
  }

  componentWillMount() {
    ////console.log(emojione);
    setTimeout(() => {
      let node = ReactDOM.findDOMNode(this.inputBox);
      node.focus();
    })
  }

  componentWillReceiveProps(nextProps) {
    //console.log(this.state);
    if(this.props.appendEmoji != nextProps.appendEmoji) {
      let appendedEmoji = this.state.inputValue + '' + nextProps.appendEmoji.native;
      // //console.log(this.state);
      // //console.log(appendedEmoji);
      // this.setState({...this.state, inputValue: ''}, function() {
      //   let node = ReactDOM.findDOMNode(this.inputBox);
      //   node.focus();
      this.setState({...this.state, inputValue: appendedEmoji, messageText: appendedEmoji}, function() {
        let node = ReactDOM.findDOMNode(this.inputBox);
        this.setEndOfContenteditable(node);
        node.focus();
      });
      // });
      
      // setTimeout(function() {
      //     node.val('');
      //     node.focus();
      //     node.val(appendedEmoji);
      // }, 0);
    }
  }

  getTextWithEmoji(text) {
    //console.log(text);
    return (
      <span className="emoji-block-in-text" dangerouslySetInnerHTML={{__html: emojione.toImage(text)}}></span>
      ); 
    //emojione.toImage(text)}
    // return (
    //   <Emoji set='emojione' emoji='hello grinning' size={23}/>
    // );
  }



  emitChange = (evt) => {
    let text = evt.currentTarget.textContent;
    let node = ReactDOM.findDOMNode(this.inputBox);
    node.textContent = '';
    this.setState({...this.state, inputValue: text, messageText: text});
    //console.log(evt.currentTarget.textContent);
  }

  keyUp = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if(this.state.inputValue.replace(/\s/g, '') !== '') {
        let node = ReactDOM.findDOMNode(this.inputBox);
        this.props.onSendMessage(this.state.inputValue);
        //console.log(node.innerHTML);
        //console.log(this.state.inputValue);
        this.setState({...this.state, inputValue: '', messageText: ''});
      }
    } else {
      let inputValue = this.state.inputValue + '' + e.key;
      let messageText = this.state.messageText + '' + (e.key === ' ' ? '&nbsp;' : e.key);
      e.preventDefault();
      //console.log(messageText);
      this.setState({...this.state, inputValue: inputValue, messageText: messageText}, function() {
        let node = ReactDOM.findDOMNode(this.inputBox);
        this.setEndOfContenteditable(node);
        node.focus();
      });
    }
    //this.setState({...this.state, inputValue: e.currentTarget.textContent});
  }

  getCaretPosition(editableDiv) {
    var caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }

  calcHeight() {
    if (this.inputBox.clientHeight !== this.state.inputBoxHeight) {
      this.setState({...this.state, inputBoxHeight: this.inputBox.clientHeight});
      this.props.heightChangeEvent(this.inputBox.clientHeight);
    }
  }

  onKeyDown = (e) => {

    this.calcHeight();
    let currentState = {...this.state};
    if(e.key === 'Backspace') {
      // currentState.inputValue = currentState.inputValue.substring(0, currentState.inputValue.length - 1);
      // this.setState({...this.state, inputValue: currentState.inputValue, messageText: currentState.inputValue}, function(){
      let node = ReactDOM.findDOMNode(this.inputBox);
      //console.log(node);
      let inputValue = '';
      let textmsg = '';
      
      //console.log(node.childNodes);
      setTimeout(() => {
        let cursorPosition = this.getCaretPosition(node);

        for(var i=0; i< node.childNodes.length; i++) {
          if(node.childNodes[i].alt) {
            if (i > 1) { cursorPosition++; }
            inputValue = inputValue + '' + node.childNodes[i].alt;
            textmsg = textmsg + '' + node.childNodes[i].alt;
          } else {

            let textContent = node.childNodes[i].textContent;
            //console.log(node.childNodes[i].textContent);
            //console.log(textContent);
            textmsg = textmsg + '' + textContent;
            let formattedText = textContent.replace(/&nbsp;/g, ' ');
            inputValue = inputValue + '' + formattedText;       
          }
        }
        this.setState({...this.state, inputValue: inputValue, messageText: textmsg}, function() {
          if(node) {
            this.setEndOfContenteditable(node);
          }
          this.calcHeight();
          // node.focus();
        });
      })
    } 
    // else if(e.keyCode === 32) {
    //   let messageText = currentState.messageText + '&nbsp;';
    //   let newInputValue = currentState.inputValue + ' ';
    //   //console.log(messageText);
    //   this.setState({...this.state, inputValue: newInputValue, messageText: messageText}); 
    // }
  }

  setCursor(node, caret) {
    if(caret != 0) {
      node.focus();
      // var el = document.getElementsByClassName("emojione")[i];
      var textNode = node.firstChild;
      //var caret = 10; // insert caret after the 10th character say
      var range = document.createRange();
      // range.setStartAfter(el);
      range.setStart(textNode, caret);
      range.setEnd(textNode, caret);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }  
  }

  setEndOfContenteditable(contentEditableElement) {
    var range,selection;
    if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
    {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    }
    else if(document.selection)//IE 8 and lower
    { 
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
  }

  render() {
    return (
      <div suppressContentEditableWarning={true}
       contentEditable="true"
       className="chat-text-field"
       ref={(el) => { this.inputBox = el; }}
       // onBlur={this.emitChange}
       dangerouslySetInnerHTML={{__html: emojione.toImage(this.state.messageText)}}
       onKeyPress={this.keyUp}
       onKeyDown={this.onKeyDown}>
      </div>   
    );
  }
}

ContentEditable.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  innerHTML: PropTypes.string,
  appendEmoji: PropTypes.object,
  heightChangeEvent: PropTypes.func
};