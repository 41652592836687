import React from 'react';
import PropTypes from 'prop-types';


import { Route, NavLink } from 'react-router-dom';

export default class Badge extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      badgeCount: 0
    };
  }

  componentWillMount() {
    this.setState({...this.state, badgeCount: this.props.badgeCount});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, badgeCount: nextProps.badgeCount});
  }

  displayBadge() {
    if (this.state.badgeCount > 0) {
      return (
        <div className="badge-wrapper">
          <div className="badge-number">{this.state.badgeCount}</div>
        </div>
      )
    } else {
      return null;
    }
  }
  
  render() {
    return (     
      <div>{this.displayBadge()}</div>
    );
  }
}

Badge.propTypes = {
  badgeCount: PropTypes.number,
};

Badge.defaultProps = {
  badgeCount: 0
};