import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/loginActions';
import { registerSocket } from 'util/socketEvents';
// import localForage from "localforage";
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoginForm from 'components/LoginForm';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loginErrors: []
    };
  }

  componentWillReceiveProps(nextProps) {
  // You don't have to do this check first, but it can help prevent an unneeded render
    if(nextProps.userInfo.userLoggedIn && !nextProps.userInfo.callInProgress) {
    } else {
      sessionStorage.removeItem('userLogged')
    }
  }

  submitLogin = (loginInfo) => {
    let { userName, password } = loginInfo;
    // localForage.clear();
    this.props.actions.loginUser({userName, password}).then(() => {
      let access_token = sessionStorage.getItem('a_t');
      registerSocket(access_token);
      console.log('login success');
      this.props.history.push("/dashboard/messages");
    })
    .catch((response) => {
      this.setState({...this.state, loginErrors: ['invalidUser']});
      //handle form errors
    })
  }

  render() {
    var year = (new Date()).getFullYear();
    return (
      <div className="flex-container max-height">
        <Header />
        <div className="row-element row">
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
          <div className="col-lg-4 col-md-8 col-sm-12 login-container">
            <div className="login-form-heading">Login</div>
            <LoginForm onLogin={this.submitLogin} otherErrors={this.state.loginErrors}/>
          </div>
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage));
