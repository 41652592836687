import React from 'react';
import { withRouter } from "react-router-dom";

import cover from 'assets/images/home.png';
import InputField from 'components/InputField';
import UserRegistration from 'containers/UserRegistration';

class HomeTemplate extends React.Component {

  constructor(props) {
    super(props);
    
  }

  onRegister = () => {

  }

  goToPrivacy = () => {
    this.props.history.push('/privacy');
  }

  goToRegister = () => {
    this.props.history.push('/register');
  }
  
  render() {
    return (
      <div className="max-height">  
        <div className="homepg1-container row">
          <div className="homepg1-container-left col-md-7 col-sm-12">
            <div className="homepg1-title">Connect with your friends</div>
            <div className="homepg1-content">Tigpix allows you to send messages instantly to your friends.
              Message anyone from anywhere. It's easy to get started, Just register with us for free and stay in touch with your friends and family
            </div>
          </div>
          <div className="homepg1-container-right col-md-5 col-sm-12">
            <div className="homepg1-rform-container">
              <div className="rform-container-title">Register for free</div>
              <UserRegistration onRegister={this.onRegister} buttonClassName="signup-btn"/>
              <div className="rform-disclaimer">
                By clicking "Start using Tigpix", you agree to our terms of service and <a className="privacy-anchor" onClick={this.goToPrivacy}> privacy policy </a>. We’ll occasionally send you account related emails.
              </div>
            </div>
          </div>
          <div className="display-mobile-sign-up col-md-5 col-sm-12">
            <button className="signup-btn" onClick={this.goToRegister}>Start using Tigpix</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HomeTemplate);

            