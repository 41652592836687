import {RECEIVE_NEW_MESSAGE, RESET_ON_RECEIVE_FLAG} from '../constants/ActionTypes';

let initialState = { 
  newMessage: {},
  userDetails: {},
  onReceiveFlag: false
}

export default function ReceiveMessageReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case (RECEIVE_NEW_MESSAGE): {
      console.log('promise started');
      return {...state, onReceiveFlag: true, newMessage: action.payload.newMessage, userDetails: action.payload.userDetails};
      break;
    }

    case (RESET_ON_RECEIVE_FLAG): {
      console.log('--------reset messages flag-------------');
      return {...state, onReceiveFlag: false, newMessage: {}, userDetails: {}};
      break;
    }
    default: {
      return state;
    }
  }
  return state;
}
