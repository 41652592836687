import React from 'react';

import { Route, NavLink } from 'react-router-dom';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/chatActions';
import ChatList from 'components/ChatList';
import ChatDetail from 'components/ChatDetail';
import Modal from 'components/Modal';
import NewMessageModal from 'containers/NewMessageModal';
import Icon from 'components/Icon';

class MessagesContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      selectedUser: {},
      userInfo: {},
      chatList: {}
    };
  }

  componentWillMount() {
    this.setState({...this.state, chatList: this.props.chatList});
  }

  componentWillReceiveProps(nextProps) {
    let clonedState = { ...this.state };
    if(nextProps.chatList.conversations.length > 0 && this.state.selectedUser.userName) {
      let selectedUserArr = _.filter(nextProps.chatList.conversations, (conv) => {
        return conv.remoteUser === this.state.selectedUser.userName;
      })
      if (selectedUserArr.length > 0) {
        clonedState.selectedUser = selectedUserArr[0].userDetails;
      }
    }
    clonedState.chatList = nextProps.chatList;
    this.setState(clonedState);
  }

  showPeopleConnectImg() {
    return !this.state.selectedUser.userName;
  }

  closeModal() {
    this.setState({...this.state, openModal: false});
  }

  openChatDetail(user) {
    this.props.actions.resetBadge({fromUser: this.props.userInfo.userName, toUser: user.userName});
    this.setState({...this.state, openModal: false, selectedUser: user})
  }

  displayMessagesSection() {
    if((this.state.chatList && this.state.chatList.conversations.length > 0) || this.state.selectedUser.userName) {
      return (
        <div className="max-height no-margin row">
          <div className="max-height messages-divider no-margin col-md-4 col-sm-4 col-xs-12">
            <ChatList 
              chatList={this.state.chatList}
              showNewMessageModal={this.handleOpen}
              userSelected={this.userSelectedFromList}
              selectedUser={this.state.selectedUser}
            />
          </div>
          <div className="max-height no-margin col-md-8 col-sm-8 col-xs-12">
            <ChatDetail showPeopleConnectImage={this.showPeopleConnectImg()} userDetails={this.state.selectedUser}/>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={this.handleOpen} className="no-convs">
          <div className="add-chat-icon"><Icon icon={'communication'} /> </div>
          <div className="no-convs-text">Click any where to start a conversation</div>
        </div>
      );
    }
  }

  userSelectedFromList = (userDetails) => {
    this.openChatDetail(userDetails);
  }

  handleOpen = () => {
    this.setState({...this.state, openModal: true});
  }

  onModalClose = () => {
    this.closeModal();
  }

  startChat = (user) => {
    this.props.actions.getUserDetails({userName: user.userName}).then((resp) => {
      this.openChatDetail(resp.payload.data);
    });
  }
  
  render() {
    return (
      <div className="max-height messages-page-container">
        {this.displayMessagesSection()}
      <Modal size="md" open={this.state.openModal} onCloseModal={this.onModalClose}>
        <NewMessageModal closeModal={this.onModalClose} startChat={this.startChat} />
      </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chatList: state.chatList,
    userInfo: state.userInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesContainer));
