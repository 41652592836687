// import React from 'react';
// import PropTypes from 'prop-types';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// import { Route, NavLink } from 'react-router-dom';

// export default class Modal extends React.Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       size: 'md',
//       showModal: false,
//       modalStyle: { display: 'block' },
//       modalClass: ['modal-content']
//     }
    
//   }

//   componentWillMount() {
//     const modalSizeClass = 'modal-' + this.props.size;
//     let modalClass = this.state.modalClass;
//     modalClass.push(modalSizeClass);
//     this.setState({...this.state, modalClass});
//   }

//   handleClose = () => {
//     const hideModalClass = 'hide-modal';
//     let modalClass = this.state.modalClass
//     modalClass.push(hideModalClass);
//     this.setState({...this.state, modalClass});

//     setTimeout(() => {
//       this.setState({...this.state, modalStyle: {}});
//     }, 300)
//   }
  
//   render() {
//     return (
//       <div style={this.state.modalStyle} className="modal">
//         <div className={this.state.modalClass.join(' ')}>
//           <span onClick={this.handleClose} className="close">&times;</span>
//           <p>Some text in the Modal..</p>
//         </div>
//       </div>
//     );
//   }
// }

// Modal.propTypes = {
//   size: PropTypes.string.isRequired,
//   open: PropTypes.bool.isRequired,
//   closeModal: PropTypes.func
// };

// Modal.defaultProps = {
//   size: 'md',
//   open: false
// };





import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { Route, NavLink } from 'react-router-dom';

export default class Modal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalShown: false,
      modalClass: ['custom-modal'],
      modalBoxClass: ['modal-content']
    }
    
  }

  componentWillMount() {
    let modalBoxClass = this.state.modalBoxClass;
    modalBoxClass.push('modal-' + this.props.size);
    this.setState({...this.state, modalBoxClass});
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }

  openModal() {
    if (this.state.modalShown){
      return;
    }
    let modalClass = this.state.modalClass;

    let modalBoxClass = this.state.modalBoxClass;
    const indexOfHideClass = modalBoxClass.indexOf('hide-modal-box');
    if(indexOfHideClass > -1) {
      modalBoxClass.splice(indexOfHideClass, 1);
    }
    modalClass.push('display-modal');
    this.setState({...this.state, modalShown: true, modalClass, modalBoxClass});
  }

  closeModal() {
    if (!this.state.modalShown){
      return;
    }
    let modalBoxClass = this.state.modalBoxClass;
    modalBoxClass.push('hide-modal-box');

    let modalClass = this.state.modalClass;
    const indexOfDisplayClass = modalClass.indexOf('display-modal');
    if(indexOfDisplayClass > -1) {
      modalClass.splice(indexOfDisplayClass, 1);
    }
    this.setState({...this.state, modalShown: false, modalClass, modalBoxClass});
    this.props.onCloseModal();
  }

  modalBody() {
    if (this.state.modalShown) {
      return <div className="max-height">{this.props.children}</div>
    } else {
      return null;
    }
  }

  handleClose = () => {
    this.closeModal();
  }

  onModalClick = (e) => {
    if (e.target.className.indexOf('custom-modal') > -1 && this.props.closeOnClickOut) {
      this.closeModal();
    }
  }
  
  render() {
    return (
      <div onClick={this.onModalClick} className={this.state.modalClass.join(' ')}>
        <div className={this.state.modalBoxClass.join(' ')}>
          {this.modalBody()}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  size: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func,
  closeOnClickOut: PropTypes.bool
};

Modal.defaultProps = {
  size: 'md',
  open: false,
  closeOnClickOut: true
};