import React from 'react';
import PropTypes from 'prop-types';

import send from 'assets/images/send.png';
import ChatDetailContainer from 'containers/ChatDetailContainer';

export default class ChatDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {}
    }  
  }

  componentWillMount() {
    let newState = {...this.state, userDetails: this.props.userDetails}
    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    let newState = {...this.state, userDetails: nextProps.userDetails}
    this.setState(newState);
  }

  returnMessageDetail() {
    if(this.props.showPeopleConnectImage || !this.state.userDetails.userName) {
      return (
        <div className="ppl-connect-img">
          <img src={send} />
          <div className="ppl-connect-img-text">Send messages instantly to your friends</div>
        </div>
      );
    } else {
      return <ChatDetailContainer userDetails={this.state.userDetails}/>
    }
  }

  render() {
    return (
      <div className="max-height">
        {this.returnMessageDetail()}
      </div>
    );
  }
}

ChatDetail.propTypes = {
  showPeopleConnectImage: PropTypes.bool.isRequired,
  userDetails: PropTypes.object.isRequired
};
