import React from 'react';

import { Route, NavLink } from 'react-router-dom';
import Header from 'components/Header';

export default class PrivacyPolicy extends React.Component {
  
  render() {
    return (      
      <div className="privacy-policy">
        <div className="policy-header">
          <div className="policy-text">Privacy Policy</div>
        </div>
        <div className="policy-container">
        <div style={{fontSize:'32px'}}>Tigpix Privacy Policy</div> <br />

        <div>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.<br />
        </div>
        <br />

        <div className="grayText"><strong>What personal information do we collect from the people that visit our blog, website or app?</strong></div><br />
        <div className="innerText">We collect Name and Email information from visitors of our site who choose to register to our application.<br /></div><br />

        <div className="grayText"><strong>When do we collect information?</strong></div><br />
        <div className="innerText">We collect information from you when you click on Sign up Button. <br /></div> 

        <br />

        <div className="grayText"><strong>How do we use your information? </strong></div><br />
        <div className="innerText"> We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:<br />
        </div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.
        </div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To send periodic emails regarding your order or other products and services.</div><br />

        <div className="grayText"><strong>How do we protect your information?</strong></div><br />
        <div className="innerText">We do not use vulnerability scanning and/or scanning to PCI standards.</div>
        <div className="innerText">We only provide articles and information. We never ask for credit card numbers.</div>
        <div className="innerText">We use regular Malware Scanning.<br /></div>
        <div className="innerText">We do not use an SSL certificate</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> We only provide articles and information. We never ask for personal or private information like names, email addresses, or credit card numbers.</div><br />

        <div className="grayText"><strong>Do we use 'cookies'?</strong></div><br />
        <div className="innerText">We do not use cookies for tracking purposes </div>
        <div className="innerText"><br />You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.<br /></div><br />
        <div className="innerText">If you turn cookies off, some features will be disabled. that make your site experience more inefficient and may not function properly.</div><br />
        <div className="innerText">However, you will still be able to place orders .</div><br />

        <div className="grayText"><strong>Third-party disclosure</strong></div><br />
        <div className="innerText">We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</div><br />

        <div className="grayText"><strong>Third-party links</strong></div><br />
        <div className="innerText">We do not include or offer third-party products or services on our website.</div><br />

        <div className="blueText"><strong>Google</strong></div><br />
        <div className="innerText">Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. <br /></div>
        <div className="innerText">We have not enabled Google AdSense on our site but we may do so in the future.</div><br />

        <div className="blueText"><strong>California Online Privacy Protection Act</strong></div><br />
        <div className="innerText">CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy.  The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. -  See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf<br /></div>
        <div className="innerText"><br /><strong>According to CalOPPA, we agree to the following:</strong><br /></div>
        <div className="innerText">Users can visit our site anonymously.</div>
        <div className="innerText">Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.<br /></div>
        <div className="innerText">Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</div>
        <div className="innerText"><br />You will be notified of any Privacy Policy changes:</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> On our Privacy Policy Page<br /></div>
        <div className="innerText">Can change your personal information:</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> By sending an email to dssridhar530@gmail.com. Changes may not reflect immediately</div>
        <div className="innerText"><br /><strong>How does our site handle Do Not Track signals?</strong><br /></div>
        <div className="innerText">We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place. </div>
        <div className="innerText"><br /><strong>Does our site allow third-party behavioral tracking?</strong><br /></div>
        <div className="innerText">It's also important to note that we do not allow third-party behavioral tracking</div><br />

        <div className="blueText"><strong>COPPA (Children Online Privacy Protection Act)</strong></div><br />
        <div className="innerText">When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control.  The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.<br /></div><div className="innerText">
        <div className="innerText">We do not collect information from children under 13.</div></div>
        <div className="innerText"><br /><strong>In order to remove your child's information please contact the following personnel: dssridhar530@gmail.com</strong></div>
        <div className="innerText"><br /><strong>We adhere to the following COPPA tenants: </strong></div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Parents can review, delete, manage or refuse with whom their child's information is shared through  contacting us directly.</div><br />

        <div className="blueText"><strong>Fair Information Practices</strong></div><br />
        <div className="innerText">The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.<br /></div>
        <div className="innerText"><strong>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</strong></div>
        <div className="innerText">We will notify you via email</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> When ever we notice it. Emails May get delayed.</div>
        <div className="innerText"><br />We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</div><br />

        <div className="blueText"><strong>CAN SPAM Act</strong></div><br />
        <div className="innerText">The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.<br /></div>
        <div className="innerText"><strong>We collect your email address in order to:</strong></div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send information, respond to inquiries, and/or other requests or questions</div>
        <div className="innerText"><br /><strong>To be in accordance with CANSPAM, we agree to the following:</strong></div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Not use false or misleading subjects or email addresses.</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Identify the message as an advertisement in some reasonable way.</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Include the physical address of our business or site headquarters.</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Monitor third-party email marketing services for compliance, if one is used.</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Honor opt-out/unsubscribe requests quickly.</div>
        <div className="innerText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Allow users to unsubscribe by using the link at the bottom of each email.</div>
        <div className="innerText"><strong><br />If at any time you would like to unsubscribe from receiving future emails, you can email us at</strong></div>
        <div className="innerText">dssridhar530@gmail.com and we will promptly remove you from <strong>ALL</strong> correspondence.</div><br />

        <div className="blueText"><strong>Contacting Us</strong></div><br />
        <div className="innerText">If there are any questions regarding this privacy policy, you may contact us using the information below.<br /></div>
        <div className="innerText">www.tigpix.com</div>Sayreville, New Jersey 08872 
        <div className="innerText">United States</div>
        <div className="innerText">dssridhar530@gmail.com</div>
        <div className="innerText"><br />Last Edited on 2018-June-02</div>
        </div>
      </div>
    );
  }
}