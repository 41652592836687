import {CALL_REGISTER, CALL_LOGIN, UNIQUE_USER} from '../constants/ActionTypes';

let initialState = { 
  userName: '',
  firstName: '',
  lastName:'',
  email: '',
  callInProgress: false,
  userLoggedIn: false,
  userNameExists: false,
  incorrectCredentials: false,
  loginError: {},
  userNameExists: false
}

export default function LoginReducer(state = initialState, action) {

  switch (action.type) {
    case (CALL_REGISTER+'_REQUEST'): {
      sessionStorage.setItem("userLogged", false);
      return {...state, callInProgress: true, userLoggedIn: false};
      break;
    }
    case (CALL_REGISTER+'_SUCCESS'): {
      let newState = {...state};
      if (action.payload.data.userRegistered) {
        let {userName, email, firstName, lastName, access_token} = action.payload.data;  
        sessionStorage.setItem("userLogged", true);
        sessionStorage.setItem("a_t", access_token);
        newState = {...state, callInProgress: false, userLoggedIn: true, userName: userName, email: email, firstName: firstName, lastName:lastName};
      }
      else {
        newState = {...state, callInProgress: false, userLoggedIn: false};
      }
      
      return newState;
      break;
    }
    case (CALL_REGISTER+'_FAIL'): {
      return {...state, callInProgress: false, userLoggedIn: false};
      break;
    }

    case (CALL_LOGIN+'_REQUEST'): {
      sessionStorage.setItem("userLogged", false);
      return {...state, callInProgress: true, userLoggedIn: false, loginError: {}};
      break;
    }
    case (CALL_LOGIN+'_SUCCESS'): {
      let newState = {...state};
      if (action.payload.data.loginSuccess) {
        let {userName, email, firstName, lastName, access_token} = action.payload.data;
        sessionStorage.setItem("a_t", access_token);
        sessionStorage.setItem("userLogged", true);
        newState = {...state, callInProgress: false, userLoggedIn: true, userName: userName, email: email, 
          firstName: firstName, lastName:lastName, loginError: {}};
      }
      else {
        newState = {...state, callInProgress: false, userLoggedIn: false};
      }
      return newState;
      break;
    }
    case (CALL_LOGIN+'_FAIL'): {
      let newState = {...state};
      let err = action.payload.request;

      if(err.status === 401) {
        newState = {...state, callInProgress: false, loginError: {errorText: 'Invalid Username or Password Please try again'}, userLoggedIn: false};
      }
      return newState;
      break;
    }

    case (UNIQUE_USER+'_REQUEST'): {
      return {...state, callInProgress: false, userNameExists: false, loginError: {}};
      break;
    }
    case (UNIQUE_USER+'_SUCCESS'): {
      let newState = {...state, userNameExists: false};

      if (action.payload.data.userExists) {
        newState = {...state, userNameExists: true};
      }
      return newState;
      break;
    }
    case (UNIQUE_USER+'_FAIL'): {
      let newState = {...state};
      return newState;
      break;
    }

    case 'REHYDRATE':
      var incoming = action.payload.userInfo;
      console.log(incoming);
      let newState = {...state};
      if (incoming) {
        let {userName, email, firstName, lastName} = incoming;  
        newState = {...state, callInProgress: false, userLoggedIn: true, userName: userName, 
          email: email, firstName: firstName, lastName:lastName};
      }
      return newState;
      break;

    default: {
      return state;
    }
  }
  return state;
}
