import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/chatActions';
import MessagesContainer from 'containers/MessagesContainer';
import PicturesPage from 'components/PicturesPage';
import Icon from 'components/Icon';

import { registerSocket, listenToReceiveMessage, unregisterSocket } from 'util/socketEvents';  //Need this line

import { Route, NavLink } from 'react-router-dom';


const iconStyles = {
  width: '30px',
  height: '30px',
  margin: '20px auto',
  color: '#fff',
  cursor: 'pointer'
};

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {open: false, loading: false};
  }

  componentWillMount() {
    this.setState({...this.state, loading: true});
    let access_token = sessionStorage.getItem('a_t');
    registerSocket(access_token);
    listenToReceiveMessage();

    this.props.actions.getChatList(this.props.userInfo.userName).then(() => {
      setTimeout(() => {
        this.setState({...this.state, loading: false});
      }, 1000)
    })
    .catch((response) => {
      //handle form errors
    })
  }

  dashboardBody() {
    const { match } = this.props;

    if(this.state.loading) {
      return(
        <div className="max-height">
          <div className="spinner"></div>
        </div>
      )
    } else {
      return (
        <div className="max-height">
          <ReactCSSTransitionGroup
            transitionName="sidenav-anim"
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnter={false}
            transitionLeave={false}>
              <div className="sidenav">
                <NavLink to={`${match.url}/pics`}  activeClassName="selected">
                  <div style={iconStyles}>
                    <Icon icon={'photo-camera'} />
                  </div>
                </NavLink>
                <NavLink to={`${match.url}/messages`} activeClassName="selected">
                  <div style={iconStyles}>
                    <Icon icon={'chat'} />
                  </div>
                </NavLink>
                <div className="logout-icon" onClick={this.logout} style={iconStyles}>
                  <Icon icon={'logout'} />
                </div>
              </div>
          </ReactCSSTransitionGroup>
          <ReactCSSTransitionGroup
            transitionName="main-anim"
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnter={false}
            transitionLeave={false}>
            <div className="main">
              <Route path={`${match.url}/messages`} component={MessagesContainer}/>
              <Route path={`${match.url}/pics`} component={PicturesPage}/>
            </div> 
          </ReactCSSTransitionGroup>
        </div>
      );
    }
  }

  logout = () => {
    sessionStorage.clear();
    unregisterSocket();
    this.props.history.push("/signin");
  }

  handleToggle() { this.setState({open: !this.state.open}); }

  handleClose() { this.setState({open: false}); }

  render() {
    return (
      <div className="app">
        {this.dashboardBody()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard));

