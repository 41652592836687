import React from 'react';
import PropTypes from 'prop-types';

export default class InputField extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showTooltip: false,
      tooltipText: '',
      hasError: false
    }
  }

  componentWillMount() {
    const { showTooltip, tooltipText, hasError } = this.props;
    this.setState({showTooltip, tooltipText, hasError});
  }

  componentWillReceiveProps(nextProps) {
    const { showTooltip, tooltipText, hasError } = nextProps;
    this.setState({showTooltip, hasError});

    //Delay tooltip text to support animation
    if(this.props.tooltipText  != nextProps.tooltipText) {
      setTimeout(()=> {
        this.setState({tooltipText});
      }, 150);
    }
  }

  setFocusToInput() {
    this.inputField.focus();
  }

  getTooltipClass = () => {
    if (this.state.showTooltip && this.state.tooltipText) {
      setTimeout(() => {
        this.setFocusToInput();
      })
      return 'tooltiptext show-tooltiptext';
    }
    return 'tooltiptext';
  }

  getInputClass = () => {
    if (this.state.hasError) {
      return 'custom-input-container has-error';
    }
    return 'custom-input-container';
  }

  onFocus = () => {
    this.setState({...this.state, showTooltip: true});
    this.props.onfocus && this.props.onfocus(this.props.name);
  }

  onBlur = () => {
    this.setState({...this.state, showTooltip: false});
    this.props.onblur && this.props.onblur(this.props.name);
  }

  render() {
    const { showTooltip, tooltipText, hasError, onfocus, onblur, ...inputProps } = this.props;
    return (
      <div className={this.getInputClass()}>
        <input 
          {...inputProps}
          className="custom-input"
          onFocus={ this.onFocus }
          onBlur={this.onBlur}
          ref={(input) => { this.inputField = input; }}
        />
        <div className={this.getTooltipClass()}>{this.state.tooltipText}</div>
      </div>
    );
  }
}

InputField.propTypes = {
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  hasError: PropTypes.bool,
  onfocus: PropTypes.func,
  onblur: PropTypes.func,
};

InputField.defaultProps = {
  showTooltip: false,
  tooltipText: '',
  hasError: false
}