import React from 'react';

import { Route, NavLink } from 'react-router-dom';
import Icon from 'components/Icon';

export default class PageNotFound extends React.Component {
  
  render() {
    return (
      <div className="page-not-found">
        <div className="hand-icon"> <Icon icon={'hand-tool'}/></div>
        <div className="hand-text">This page isn't available</div>
        <div className="hand-text">The link you followed may be broken, or the page may have been removed.</div>
      </div>
    );
  }
}
