import { combineReducers } from 'redux';
import ChatReducer from './chatReducer';
import LoginReducer from './LoginReducer';
import MessageReducer from './MessageReducer';
import ReceiveMessageReducer from './ReceiveMessageReducer';
import UserListReducer from './UserListReducer';
import {routerReducer} from 'react-router-redux';

import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

const config = {
  key: "userInfo",
  storage
};

const PersistLoginReducer = persistReducer(config, LoginReducer);

const rootReducer = combineReducers({
  chatList: ChatReducer,
  userInfo: PersistLoginReducer,
  getUsersList: UserListReducer,
  sendMessage: MessageReducer,
  routing: routerReducer,
  receiveMessage: ReceiveMessageReducer
});

export default rootReducer;
