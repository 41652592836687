import {USER_LIST} from '../constants/ActionTypes';

let initialState = { 
  usersList: [],
  loadingUsers: false,
  usersFound: false,
  error: {}
}

import { fromJS, toJS } from 'immutable';

export default function UserListReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case USER_LIST: {
      let newState = fromJS(state);
      let changedState = newState.set('loadingUsers', true);
      return changedState.toJS();
      break;
    }
    case (USER_LIST+'_SUCCESS'): {
      // console.log('promise fulfilled');
      let response = action.payload.data;
      // console.log(response);
      return {...state, loadingUsers: false, usersList: response.users, usersFound: response.usersFound};
      break;
    }
    case (USER_LIST+'_FAIL'): {
      console.log('promise rejected');
      return {...state, loadingUsers: false, error: {someError: true}};
      break;
    }
    default: {
      return state;
    }
  }
  return state;
}
