import * as types from '../constants/ActionTypes';

// import { PreAuthAxios, AuthAxios } from '../util/axiosAPI';

export function getUserListByChar(userName, data) {
  const url = '/user/getList/' + userName + '/'+ data;
  return {
    type: types.USER_LIST,
    payload: {
      request:{
        method: 'get',
        url: url
      }
    }
    //payload: AuthAxios.get(url)
  };
}