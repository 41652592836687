import React from 'react';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';
import Icon from 'components/Icon';

import ChatListItem from 'components/ChatListItem';
import { listenToPresenceStatus } from 'util/socketEvents';
import communication from 'assets/images/communication.png';

export default class ChatList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedUser: {}
    }

    this.clickedOnUser = this.clickedOnUser.bind(this);
  }

  componentWillMount() {
    this.setState({...this.state, selectedUser: this.props.selectedUser});
    // this.props.getChatList('sdasari2');
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.chatList.conversations);
    this.setState({...this.state, selectedUser: nextProps.selectedUser});
  }

  clickedOnUser = (user) => {
    this.props.userSelected(user.userDetails);
  }

  getChatList() {
    var chatList;
    let self = this;
    if (this.props.chatList.conversations.length > 0) {
      let chatListMap = [...this.props.chatList.conversations];
      let orderByConvs = _.sortBy(chatListMap, (conv) => {return (conv.ts)}).reverse();
      chatList = orderByConvs.map(function(chatConv) {
        listenToPresenceStatus(chatConv.userDetails.userName);
        const name = chatConv.userDetails.firstName + ' ' +chatConv.userDetails.lastName;
        let currentConv = {...chatConv};
        currentConv.name = name;
        let selectedUserClass='';
        if (self.state.selectedUser.userName === currentConv.userDetails.userName) {
          selectedUserClass = 'selected-chat-list';
        }
        return (
          <div key={currentConv.remoteUser} className={selectedUserClass}>
            <ChatListItem userDetails={currentConv} onUserClick={self.clickedOnUser}/>
          </div>
        );
      })
    } else {
      chatList = <div onClick={this.openModal} className="no-convs">
                    <div className="no-convs-text">You have no messages</div>
                  </div>
    }

    return chatList;
  }

  openModal = () => {
    this.props.showNewMessageModal();
  }

  render() {
    return (      
      <div className="max-height">
        <div className="chatlist-header">
          <div className="heading">Messages</div>
          <div onClick={this.openModal} className="add-icon">
            <Icon icon={'add'} />
          </div>
        </div>
        <div className="chatlist-body">
          {this.getChatList()}
        </div>

      </div>
    );
  }
}

ChatList.propTypes = {
  chatList: PropTypes.object,
  showNewMessageModal: PropTypes.func,
  userSelected: PropTypes.func,
  selectedUser: PropTypes.object
};

ChatList.defaultProps = {
  chatList: {},
  selectedUser: {}
}