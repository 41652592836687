import React from 'react';
import PropTypes from 'prop-types';

import InputField from 'components/InputField';
import { validateInputField } from 'util/commonFunctions';
import messages from 'constants/Messages.json';

const margin20 = {
  marginBottom: '20px',
  paddingRight: '0px'
};

const fieldValidations = {
  userName: {required: true, min: 6, max: 20},
  password: {required: true, min:6, max: 20}
};

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      userName: { text: '', hasError: false, errorText: '', showError: false },
      password: { text: '', hasError: false, errorText: '', showError: false }
    }
  }

  componentWillMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.otherErrors.length > 0) {
      let clonedState = this.state;
      clonedState['userName'] = {...clonedState['userName'], 
                                  hasError: true,
                                  errorText: messages.errors['userName'][nextProps.otherErrors[0]],
                                  showError: true
                                };
      clonedState['password'] = { text: '', hasError: true, errorText: '', showError: false };
      this.setState(clonedState);
    }

  }

  onInputChange = (e) => {
    let clonedState = {...this.state};

    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const error = validateInputField(fieldValue, fieldValidations[fieldName]);
    if(error && clonedState[fieldName]['errorText']) {
      clonedState[fieldName]['showError'] = clonedState[fieldName]['errorText'].length > 0;
      clonedState[fieldName]['errorText'] = messages.errors[fieldName][error];
    } else {
      clonedState[fieldName]['errorText'] = '';
      clonedState[fieldName]['hasError'] = false;
      clonedState[fieldName]['showError'] = false;
    }

    clonedState[fieldName]['text'] = fieldValue;
    this.setState(clonedState);
  };

  onFocus = (inputName) => {
    let clonedState = {...this.state};
    clonedState[inputName]['showError'] = true;
    this.setState(clonedState);
  }

  onBlur = (inputName) => {
    let clonedState = {...this.state};
    clonedState[inputName]['showError'] = false;
    this.setState(clonedState);
  }

  submitLogin = () => {
    let clonedState = {...this.state};
    let foundError = false;

    _.forEach(fieldValidations, function(value, key) {
      let error = validateInputField(clonedState[key]['text'], value);
      if(error) {
        clonedState[key]['hasError'] = true;
        clonedState[key]['errorText'] = messages.errors[key][error];
        if(!foundError) {
          clonedState[key]['showError'] = true;
          foundError = true;
        }
      }
    });
    this.setState(clonedState);

    if(foundError) {
      return;
    }

    this.props.onLogin && this.props.onLogin({userName: clonedState.userName.text, password: clonedState.password.text});
  }

  render() {
    return (
      <div>
        <div className="row">
          <div style={margin20} className={"col-md-12 col-sm-12"}>
            <InputField 
              type="text"
              name="userName"
              placeholder="User Name"
              value={this.state.userName.text}
              onChange={this.onInputChange}
              hasError={this.state.userName.hasError}
              tooltipText={this.state.userName.errorText}
              showTooltip={this.state.userName.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>               
        </div>
        <div className="row">
          <div style={margin20} className={"col-md-12 col-sm-12"}>
            <InputField 
              type="password"
              name="password"
              placeholder="Password"
              value={this.state.password.text}
              onChange={this.onInputChange}
              hasError={this.state.password.hasError}
              tooltipText={this.state.password.errorText}
              showTooltip={this.state.password.showError}
              onfocus={this.onFocus}
              onblur={this.onBlur}
             />
          </div>                 
        </div>
        <div className="row">
          <div style={margin20} className="col-md-12 col-sm-12 login-btn-container">
            <button className="login-btn" onClick={this.submitLogin}>LOGIN</button>
          </div>                 
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  otherErrors: PropTypes.array
};

LoginForm.defaultProps = {
  onLogin: null,
  otherErrors: []
}