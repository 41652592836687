import React from 'react';
import PropTypes from 'prop-types';

import { Emoji } from 'emoji-mart';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import Icon from 'components/Icon';

export default class EmojiPicker extends React.Component {
  constructor(props) {
    super(props);

    this.showEmojiPicker = this.showEmojiPicker.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.addEmoji = this.addEmoji.bind(this);
    this.state = {
      showPicker: false,
      loadEmoji: false
    }
    
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    setTimeout(() => {
      this.setState({...this.state, loadEmoji: true});
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.closeEmoji) {
      this.setState({showPicker: false});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  clickOnPickerIcon() {

  }

  handleClickOutside(event) {
    var clickedOn = event.target.parentNode.getAttribute('class');
    if(clickedOn && clickedOn.indexOf('emoji') > -1) {
      this.setState({showPicker: true});
    } else {
      this.setState({showPicker: false});
    }
  }

  emojiPicker() {
    var classNameArray = ["emoji-picker"];
    if (this.state.showPicker) {
      classNameArray.push("show-emoji-picker");
    }
    if (this.state.loadEmoji) {
      return ( 
        <div className={classNameArray.join(' ')}>
          <Picker skin={1} set='emojione' perLine={9} showPreview='false' 
            style={{ width: '350px', position: 'absolute', top: '-47px' }} 
            exclude={['recent']}
            onClick={this.addEmoji}/>
        </div>
      )
    } else {
      return null;
    }
  }

  showEmojiPicker() {
    this.setState({showPicker: true});
  }

  addEmoji(e) {
    this.props.onEmojiClick(e);
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.emojiPicker()}
        <div className="smiley-icon" onClick={this.showEmojiPicker}>
          <Icon icon={'happiness'}/>
        </div>
      </div>
    );
  }
}

EmojiPicker.propTypes = {
  onEmojiClick: PropTypes.func.isRequired,
  closeEmoji: PropTypes.bool
};

