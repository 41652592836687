import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import axiosBackend from 'util/axiosBackend';
import rootReducer from 'reducers';
import axiosMiddlewareOptions from 'util/httpInterceptor';


function configureStoreProd(initialState) {
  const middlewares = [
    // Add other middleware on this line...
    axiosMiddleware(axiosBackend, axiosMiddlewareOptions),
  ];

  const store = createStore(rootReducer, undefined, compose(
    applyMiddleware(...middlewares)
    )
  );

  let persistor = persistStore(store);

  return { persistor, store };
}

function configureStoreDev(initialState) {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    axiosMiddleware(axiosBackend, axiosMiddlewareOptions),
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(rootReducer, undefined, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );

  let persistor = persistStore(store);

  return { persistor, store };
}

const { store, persistor } = process.env.NODE_ENV === 'production' ? configureStoreProd() : configureStoreDev();

export { store, persistor };