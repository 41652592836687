import React from 'react';
import { Route, NavLink } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import HomeTemplate from 'components/HomeTemplate';

export default class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      isPageLoading: false 
    };
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({ ...this.state, isPageLoading: false });
    // }, 500)
  }

  showPageBody() {
    if(this.state.isPageLoading) {
      return(
        <div>
          <div className="spinner"></div>
        </div>
      )
    } else {
      return (
        <HomeTemplate />
      );
    }
  }
  
  render() {
    var year = (new Date()).getFullYear();
    return (
      <div className="app">
        <Header />
        <div className="template-container" role="main">
          {this.showPageBody()}
        </div>

      </div>
    );
  }
}
