import * as types from '../constants/ActionTypes';

// example of a thunk using the redux-thunk middleware
export function getChatList(userId) {
  const url = '/convs/'+ userId;
  return {
    type: types.GET_CHAT_LIST,
    payload: {
      request:{
        method: 'get',
        url: url
      }
    }
  };
}

export function resetBadge(data) {
  console.log(data);
  const url = '/resetBadge';
  return {
    type: types.RESET_BADGE,
    payload: {
      request:{
        method: 'post',
        url: url,
        data: data
      }
    }
    //payload: AuthAxios.post(url,data)
  };
}

export function getUserDetails(data) {
  const url = '/user/'+ data.userName;
  return {
    type: types.GET_USER_DETAILS,
    payload: {
      request:{
        method: 'get',
        url:url
      }
    }
    //payload: PreAuthAxios.get(url)
  };
}

// export function calculateFuelSavings(settings, fieldName, value) {
//   return {
//     type: types.CALCULATE_FUEL_SAVINGS,
//     dateModified: getFormattedDateTime(),
//     settings,
//     fieldName,
//     value
//   };
// } 