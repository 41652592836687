import * as types from '../constants/ActionTypes';

//import { PreAuthAxios, AuthAxios } from '../util/axiosAPI';

// example of a thunk using the redux-thunk middleware
export function registerUser(data) {
  const url = '/auth/register';
  return {
    type: types.CALL_REGISTER,
    payload: {
      request:{
        method: 'post',
        url: url,
        data: data
      }
    }
    //payload: PreAuthAxios.post(url,data)
  };
}

export function loginUser(data) {
  // return dispatch => {
  //   const url = '/api/v1/auth/login';
  //   return dispatch({
  //     type: types.CALL_LOGIN,
  //     payload: PreAuthAxios.post(url,data)
  //   });
  // }
  const url = '/auth/login';
  return {
    type: types.CALL_LOGIN,
    payload: {
      request:{
        method: 'post',
        url: url,
        data: data
      }
    }
    //payload: PreAuthAxios.post(url,data)
  };
}

export function isUniqueUser(data) {
  const url = '/user/valid/'+ data.userName;
  return {
    type: types.UNIQUE_USER,
    payload: {
      request:{
        method: 'get',
        url:url
      }
    }
    //payload: PreAuthAxios.get(url)
  };
}