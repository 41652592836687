import React from 'react';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';

import AvatarInitial from 'components/AvatarInitial';

export default class UserListItem extends React.Component {

  constructor(props) {
    super(props);  
  }

  componentWillMount() { }

  clickedOnUser = () => {
    this.props.onUserClick(this.props.userDetails);
  }
  
  render() {
    return (      
      <div className="userlist-item" onClick={this.clickedOnUser}>
        <div className="avatar-block">
          <AvatarInitial name={this.props.userDetails.name} size={'md'} roundAvatar={true}/>
        </div>
        <div className="text-block">
          <div className="conv-name font-20">{this.props.userDetails.name}</div>
          <div className="text-message font-16">{this.props.userDetails.userName}</div>
        </div>
      </div>
    );
  }
}

UserListItem.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onUserClick: PropTypes.func
};

UserListItem.defaultProps = {
  userDetails: {firstName: '', lastName: '', userName: '', name: ''}
}