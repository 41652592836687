import _ from 'lodash';

var buildConversationObjFromMessage = function(conversation, message, messageDirection, userDetails) {
  var returnObj = {
    body: message.body,
    from: message.from,
    to: message.to,
    groupId: message.groupId || '',
    isGroup: message.isGroup,
    messageType: message.messageType,
    ts: message.ts
  };

  if(conversation) {
    var conversationObj = {...conversation};
    returnObj.badgeCount = messageDirection === 'r' ? conversationObj.badgeCount + 1 : conversationObj.badgeCount;
    returnObj.messageCount = conversationObj.messageCount + 1;
    returnObj.remoteUser = conversationObj.remoteUser;
    returnObj.name = conversationObj.name;
    returnObj.userDetails = conversationObj.userDetails;
  } else {
    returnObj.badgeCount = messageDirection === 'r' ? 1 : 0;
    returnObj.messageCount = 1;
    returnObj.remoteUser = messageDirection === 'r' ? message.from : message.to;
    returnObj.name = userDetails.firstName + ' ' + userDetails.lastName;
    returnObj.userDetails = userDetails;
  }

  return returnObj;
}

//return value for below function should match constants/Messages.json errors
var validateInputField = (fieldValue, fieldValidations) => {
  let returnVal = '';
  _.forEach(fieldValidations, function(val, key) {
  
    switch(key.toString()) {
      case 'required':
        if(val) {
          if(fieldValue.replace(/\s+/g, '') == '') {
            returnVal = 'required';
          }
        }
        break;
      case 'min':
        if(fieldValue.length < val && fieldValidations['required']) {
          returnVal = 'min';       
        }
        break;
      case 'max':
        if(fieldValue.length > val && fieldValidations['required']) {
          returnVal = 'max';
          
        }
        break;
      case 'pattern':
        if(!fieldValue.match(new RegExp(val)) && fieldValidations['required']) {
          returnVal = 'pattern';
        }
        break;
      default:
        break;
    }
    if(returnVal != '') {
      return false;
    }
  });
  return returnVal;
}

var generateTrackId = () => {
  /* tslint:disable:no-bitwise */
  const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

  let chars = CHARS;
  let uuid = [];
  let radix = chars.length;

  // rfc4122, version 4 form
  let r;

  // rfc4122 requires these characters
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  uuid[14] = '4';

  // Fill in random data.  At i==19 set the high bits of clock sequence as
  // per rfc4122, sec. 4.1.5
  for (let i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | Math.random() * 16;
      uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
    }
  }

  return uuid.join('');
  /* tslint:enable:no-bitwise */
}

export { buildConversationObjFromMessage, validateInputField, generateTrackId };
