import React from 'react';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';

import Icon from 'components/Icon';

export default class SearchInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchText: ''
    }
    
  }

  componentWillMount() {
    
  }

  componentDidMount() {
    setTimeout(() => {
      this.searchInput.focus();
    }, 100)
  }

  onInputChange = (e) => {
    this.setState({...this.state, searchText: e.target.value});
    this.props.onTextChange(e.target.value);
  }

  handleKeyPress = (event) => {
    switch(event.keyCode) {
      case 27:
        console.log('esc');
        this.props.onEscPress();
        break;
      case 40:
        this.props.onArrowPress('down');
        break;
      case 38:
        this.props.onArrowPress('up');
        break;
      case 13:
        this.props.onEnterPress();
        break;
      default:
        break;
    }
  }
  
  render() {
    return (
      <div className="input-box">
        <div className="search-icon">
          <Icon icon={'magnifying-glass'} />
        </div>
        <input
          ref={(input) => { this.searchInput = input; }}
          className="search-input"
          type="text"
          placeholder="Search People"
          value={this.state.searchText}
          onChange={this.onInputChange}
          onKeyUp={this.handleKeyPress}
        />
      </div>
    );
  }
}

SearchInput.propTypes = {
  onTextChange: PropTypes.func,
  onArrowPress: PropTypes.func,
  onEscPress: PropTypes.func,
  onEnterPress: PropTypes.func
};