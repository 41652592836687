import React from 'react';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';

import AvatarInitial from 'components/AvatarInitial';
import Badge from 'components/Badge';
import moment from 'moment';
import emojione from 'emojione';

export default class ChatListItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userDetails: {}
    }

  }

  componentWillMount() {
    // console.log(this.props.userDetails);
    this.setState({...this.state, userDetails: this.props.userDetails});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, userDetails: nextProps.userDetails});
  }

  returnMessage(userDetails) {
    if (userDetails.messageType === 'SMS-Text') {
      return <div className="emoji-block-in-text-chatlist" dangerouslySetInnerHTML={{__html: emojione.toImage(userDetails.body)}}></div>

    } else {
      return 'other type of message';
    }
  }

  displayTime() {
    if (moment(this.state.userDetails.ts).format('YYYY MM DD') == moment().format('YYYY MM DD')) {
      return moment(this.state.userDetails.ts).format('h:mm a');
    } else {
      return moment(this.state.userDetails.ts).format('MMM D');
    }
  }

  clickedOnUser = () => {
    this.props.onUserClick(this.props.userDetails);
  }
  
  render() {
    return (      
      <div className="chatlist-item" onClick={this.clickedOnUser}>
        <div className="avatar-block">
          <AvatarInitial name={this.state.userDetails.name} size={'md'} />
        </div>
        <div className="text-block">
          <div className="conv-name">{this.state.userDetails.name}</div>
          <div className="text-message">{this.returnMessage(this.props.userDetails)}</div>
        </div>
        <div className="timestamp-block">
          <div className="ts">{this.displayTime()}</div>
          <div className="badge-container"><Badge badgeCount={this.state.userDetails.badgeCount} /></div>
        </div>
      </div>
    );
  }
}

ChatListItem.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onUserClick: PropTypes.func
};

ChatListItem.defaultProps = {
  userDetails: {}
}