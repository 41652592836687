import * as types from '../constants/ActionTypes';

// import { PreAuthAxios, AuthAxios } from '../util/axiosAPI';

// example of a thunk using the redux-thunk middleware
export function sendMessage(data) {
  const url = '/message';
  return {
    type: types.SEND_MESSAGE,
    payload: {
      request:{
        method: 'post',
        url: url,
        data: data
      }
    }
    //payload: AuthAxios.post(url,data)
  };
}

export function changeStoreWithTempMessage(oldMessageId, newMessage, userDetails) {
	return {
  	type: types.UPDATE_WITH_TEMP_MESSAGE,
  	payload: { oldMessageId: oldMessageId, newMessage: newMessage, userDetails: userDetails }
  }
}

export function changeStoreWithMessageId(tempId, newMessage, userDetails) {
	return {
  	type: types.UPDATE_TEMP_WITH_MESSAGEID,
  	payload: { tempId: tempId, newMessage: newMessage, userDetails: userDetails }
  }
}

export function loadMoreMessages(data) {
	const url = '/loadmore/messages/';
  return {
    type: types.LOAD_MORE_MESSAGES,
    payload: {
      request:{
        method: 'post',
        url:url,
        data: data
      }
    }
  };
}

export function resetOnReceiveFlag() {
	return {
  	type: types.RESET_ON_RECEIVE_FLAG,
  	payload: {}
  }
}