import React from 'react';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';
import _ from 'lodash';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";

import * as actions from 'actions/userListActions';

import SearchInput from 'components/SearchInput';
import UserList from 'components/UserList';

const userItemHeight = 81;

const users = [
  {firstName: 'Sai Sridhar', lastName: 'Dasari', userName: 'Saisridhar2'},
  {firstName: 'Deepak Chandu', lastName: 'Mokkapati', userName: 'deepak2'},
  {firstName: 'Dinesh Reddy', lastName: 'Julakanti', userName: 'dinesh2'},
  {firstName: 'Sharath Reddy', lastName: 'Ayyadapu', userName: 'sharath456'},
  {firstName: 'Sri Lakshmi', lastName: 'Balusu', userName: 'srilaxmi2'},
  {firstName: 'Laksmi Sowjanya', lastName: 'Dasari', userName: 'sowji456'},
  {firstName: 'Deepika', lastName: 'Gannamaneni', userName: 'deepika234'},
  {firstName: 'Saravanam', lastName: 'Madheswaran', userName: 'saravanam2'},
  {firstName: 'Nageswara Rao', lastName: 'Dasari', userName: 'nag2345'},
  {firstName: 'Ratna', lastName: 'Dodda', userName: 'ratnadodda2'},
  {firstName: 'Michael', lastName: 'Jackson', userName: 'mike1234'},
  {firstName: 'John', lastName: 'Doe', userName: 'johndoe123'},
  {firstName: 'Nava Chaithanya', lastName: 'Kolli', userName: 'navagat6'}
]

class NewMessageModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      hoveredUser: {},
      hoverIndex: 0,
      selectedUser: {},
      userList: [],
      loadingUsers: false,
      usersFound: false,
      scrollPosition: 0
    }  
  }

  componentWillMount() {
    this.setHoveredUser(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.getUsersList.usersFound) {
      if(this.state.userList.length !== nextProps.getUsersList.usersList.length) {
        const { usersFound, loadingUsers, usersList } = nextProps.getUsersList;
        
        let newState = {...this.state, userList: usersList, usersFound, loadingUsers: false };
        this.setState(newState, function() {
          this.setHoveredUser(0, 0);
        });
      } else {
        this.setState({...this.state, loadingUsers: false})
      }
    } else {
      let newState = { ...this.state, userList: [], usersFound: false, loadingUsers: false };
      this.setState(newState);
    }
  }

  _searchOnServer = _.debounce(function(value){
    this.callBackendForUsers(value);
  }, 200);

  setHoveredUser(hoverIndex, scrollPosition) {
    scrollPosition = scrollPosition > 0 ? scrollPosition : 0;
    if(this.state.userList.length > 0) {
      this.setState({...this.state, hoveredUser:this.state.userList[hoverIndex], hoverIndex, scrollPosition});
    }
  }

  callBackendForUsers(char) {
    let newState = {};
    if(char) {
      newState = { ...this.state, loadingUsers: true};
      this.props.actions.getUserListByChar(this.props.userInfo.userName, char);
    } else {
      newState = { ...this.state, userList: [], usersFound: false };
    }
    this.setState(newState);
  }

  onTextChange = (text) => {
    this.setState({...this.state, searchText: text, loadingUsers: true});
    this._searchOnServer(text);
  }

  closeMessageModal = () => {
    this.props.closeModal();
  }

  onUserSelect = (user) => {
    this.props.startChat(user);
    console.log(user);
  }

  arrowPress = (arrowDirection) => {
    if (arrowDirection === 'up') {
      if (this.state.hoverIndex > 0) {
        let hoverIndex = this.state.hoverIndex;
        hoverIndex--;
        let scrollPosition = this.state.scrollPosition;
        if (hoverIndex < (this.state.userList.length - 3)) {
          scrollPosition = scrollPosition - 81;
        }
        this.setHoveredUser(hoverIndex, scrollPosition);
      }
    } else {
      if (this.state.hoverIndex < (this.state.userList.length - 1)) {
        let hoverIndex = this.state.hoverIndex;
        hoverIndex++;
        let scrollPosition = this.state.scrollPosition;
        if (hoverIndex > 3) {
          scrollPosition = scrollPosition + 81;
        }
        this.setHoveredUser(hoverIndex, scrollPosition);
      }
    }

  }

  enterPress = () => {
    if (this.state.hoveredUser.userName) {
      this.props.startChat(this.state.hoveredUser);
    }
  }
  
  render() {
    return (      
      <div className="new-message-container">
        <SearchInput
          onTextChange={this.onTextChange}
          onEscPress={this.closeMessageModal}
          onArrowPress={this.arrowPress}
          onEnterPress={this.enterPress}
        />
        <UserList
          userList={this.state.userList}
          hoveredUser={this.state.hoveredUser}
          onUserSelect={this.onUserSelect}
          scrollPosition={this.state.scrollPosition}
          searchText={this.state.searchText}
          usersFound={this.state.usersFound}
          loadingUsers={this.state.loadingUsers}
        />
      </div>
    );
  }
}

NewMessageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  startChat: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    getUsersList: state.getUsersList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMessageModal));