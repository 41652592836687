import React from 'react';
import { Route, NavLink } from 'react-router-dom';

import Icon from 'components/Icon';

export default class PicturesPage extends React.Component {
  
  render() {
    return (
      <div className="pictures-page">
        <div className="big-camera-icon"><Icon icon={'photo-camera'} /> </div>
        <div className="no-convs-text">Share your favorite pictures with your friends </div>
        <div className="no-convs-text">Feature Coming Soon...</div>
      </div> 
    );
  }
}
