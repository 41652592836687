export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export const UNIQUE_USER = 'UNIQUE_USER';
export const CALL_LOGIN = 'CALL_LOGIN';
export const CALL_REGISTER = 'CALL_REGISTER';
export const USER_LIST = 'USER_LIST';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const UPDATE_WITH_TEMP_MESSAGE = 'UPDATE_WITH_TEMP_MESSAGE';
export const UPDATE_TEMP_WITH_MESSAGEID = 'UPDATE_TEMP_WITH_MESSAGEID';
export const CHANGE_USER_PRESENCE = 'CHANGE_USER_PRESENCE';
export const RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE';
export const RESET_ON_RECEIVE_FLAG = 'RESET_ON_RECEIVE_FLAG';
export const CHAT_LIST_ON_RECEIVE_MESSAGE = 'CHAT_LIST_ON_RECEIVE_MESSAGE';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const RESET_BADGE = 'RESET_BADGE';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';