import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Route, NavLink } from 'react-router-dom';
import  UserListItem from 'components/UserListItem';

export default class UserList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      hoveredUser: {},
      usersFound: false,
      searchText: '',
      loadingUsers: false
    }
  }

  componentWillMount() {
    const { hoveredUser, userList, usersFound, searchText, loadingUsers } = this.props;
    this.setState({...this.state, hoveredUser, userList, usersFound, loadingUsers});
  }

  componentWillReceiveProps(nextProps) {
    let hoveredUser = this.state.hoveredUser;
    if (this.props.scrollPosition !== nextProps.scrollPosition) {
      this.scrollUserList(nextProps.scrollPosition);
    }
    if (this.props.hoveredUser !== nextProps.hoveredUser) {
      hoveredUser = nextProps.hoveredUser;
    }
    const { usersFound, searchText, loadingUsers } = nextProps;
    this.setState({...this.state, 
                    userList: nextProps.userList,
                    hoveredUser,
                    usersFound,
                    searchText,
                    loadingUsers
                  });
  }

  scrollUserList(scrollPosition) {
    this.scrollTo(this.userListElement, scrollPosition, 200);
  }

  scrollTo(element, to, duration) {
    let self = this;
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = self.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
  }

  easeInOutQuad(t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };

  showUsersList() {
    console.log(this.state.usersFound);
    var userList;
    var self = this;
    if (this.state.usersFound) {
      userList = this.state.userList.map(function(user) {
        var name = user.firstName + ' ' +user.lastName;
        user.name = name;
        let hoverClassName = '';
        if (user.userName === self.state.hoveredUser.userName) {
          hoverClassName = 'hover-user';
        }
        return ( 
          <div key={user.userName} className={hoverClassName}>
            <UserListItem userDetails={user} onUserClick={self.onUserClick}/>
          </div>
        );
      })
    } else {
      console.log(this.state.searchText);
      if (this.state.searchText) {
        if(this.state.loadingUsers) {
          userList = (
            <div className="user-loading-spinner">
              <div className="spinner"></div>
            </div>
          )
        } else {
          userList = <div className="no-matches"> No Matches Found</div>
        }
      } else {
        userList = null;
      }
    }
    return userList;
  }

  onUserClick = (userDetails) => {
    this.props.onUserSelect(userDetails);
  }
  
  render() {
    return (      
      <div className="user-list" ref={(el) => { this.userListElement = el; }}>
        {this.showUsersList()}
      </div>
    );
  }
}

UserList.propTypes = {
  userList: PropTypes.array,
  hoveredUser: PropTypes.object,
  scrollPosition: PropTypes.number,
  onUserSelect: PropTypes.func,
  searchText: PropTypes.string,
  usersFound: PropTypes.bool,
  loadingUsers: PropTypes.bool
};

UserList.defaultProps = {
  usersList: [],
  hoveredUser: {},
  scrollPosition: 0,
  searchText: '',
  usersFound: false,
  loadingUsers: false
}
