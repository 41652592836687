import React from 'react';
import PropTypes from 'prop-types';

export default class AvatarInitial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      circle: {
        backgroundColor: '#55b976',
        borderRadius: '25%',
        height: '60px',
        textAlign: 'center',
        width: '60px',
        boxShadow: '0 1px 3px 0 rgba(0, 109, 139, 0.5)'
      },
      initials: {
        color: '#fff',
        fontSize: '24px', /* 50% of parent */
        lineHeight: '1',
        position: 'relative',
        top: '17px',
        fontWeight: '400'
      }
    }
    
  }

  componentWillMount() {
    this.setSize();
  }

  setSize() {
    let clonedState = {...this.state};
    switch (this.props.size) {
      case "sm":
        clonedState.circle.width = '35px';
        clonedState.circle.height = '35px';

        clonedState.initials.top = '3px';
        clonedState.initials.fontSize = '16px';
        break;
      case "md":
        clonedState.circle.width = '60px';
        clonedState.circle.height = '60px';

        clonedState.initials.top = '17px';
        clonedState.initials.fontSize = '24px';
        break;
      case "lg":
        // code...
        break;
    }
    clonedState.circle.borderRadius = this.props.roundAvatar ? '50%' : '25%';
    this.setState(clonedState);
  }

  getInitials (name) {
    var returnVal = '';
    const nameSplit = name.split(' ');
    if (nameSplit.length === 1) {
      returnVal = nameSplit[0].charAt(0).toUpperCase();
    } else {
      returnVal = nameSplit[0].charAt(0).toUpperCase() + '' + nameSplit[nameSplit.length -1].charAt(0).toUpperCase();
    }
    return returnVal;
  }

  render() {
    return (
      <div style={this.state.circle}>
        <span style={this.state.initials}>{this.getInitials(this.props.name)}</span>
        <span></span>
      </div>
    );
  }
}

AvatarInitial.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  roundAvatar: PropTypes.bool
};

AvatarInitial.defaultProps = {
  name: '',
  size: 'md',
  roundAvatar: false
};
