import * as types from '../constants/ActionTypes';

// import { PreAuthAxios, AuthAxios } from '../util/axiosAPI';

export function changeUserPresence(data) {
	return {
  	type: types.CHANGE_USER_PRESENCE,
  	payload: data
  }
}

export function receiveNewMessage(newMessage, userDetails) {
	return {
  	type: types.RECEIVE_NEW_MESSAGE,
  	payload: {newMessage: newMessage, userDetails: userDetails}
  }
}

export function updateChatListOnMessage(newMessage, userDetails) {
	return {
  	type: types.CHAT_LIST_ON_RECEIVE_MESSAGE,
  	payload: {newMessage: newMessage, userDetails: userDetails}
  }
}
 
