import {SEND_MESSAGE, LOAD_MORE_MESSAGES} from '../constants/ActionTypes';

let initialState = { 
  messageId: '',
  trackId: '',
  status: '',
  messageSent: false,
  sendingInProgress: false,
  error: {}
}

export default function MessageReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case (SEND_MESSAGE+'_REQUEST'): {
      console.log('promise started');
      return {...state, sendingInProgress: true};
      break;
    }
    case (SEND_MESSAGE+'_SUCCESS'): {
      console.log('promise fulfilled');
      let response = action.payload.data;
      console.log(response);
      return {...state, sendingInProgress: false, messageId: response.messageId,
      			trackId: response.trackId, status: response.status, messageSent: response.messageSent};
      break;
    }
    case (SEND_MESSAGE+'_FAIL'): {
      console.log('promise rejected');
      return {...state, sendingInProgress: false, error: {someError: true}};
      break;
    }
    // case (LOAD_MORE_MESSAGES+'_REQUEST'): {
    //   console.log('promise started');
    //   return {...state};
    //   break;
    // }
    // case (LOAD_MORE_MESSAGES+'_SUCCESS'): {
    //   console.log('promise fulfilled');
    //   let response = action.payload.data;
    //   console.log(response);
    //   return {...state};
    //   break;
    // }
    // case (LOAD_MORE_MESSAGES+'_FAIL'): {
    //   console.log('promise rejected');
    //   return {...state};
    //   break;
    // }
    default: {
      return state;
    }
  }
  return state;
}
