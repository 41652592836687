const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (getState, config) => {
        if (sessionStorage.getItem('a_t')) {
          config.headers['Authorization'] = sessionStorage.getItem('a_t');
        }
        return config
      }
    ],
    response: [
      (getState, response) => {
        return response
      }
    ]
  }
}

export default axiosMiddlewareOptions;